import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Text from './../Text'
import Grid from './../VGrid'
import Button from './../Button'
import { colors } from '../../Themes'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

function TryDP() {
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: '55px 10% 48px',
      marginTop: 120,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 5,
      gap: 24
      // [theme.breakpoints.down('xs')]: {
      //   padding: '20px',
      // },
    },
    info: {
      textAlign: 'center',
      margin: '20px 0'
    },
    text: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '26px !important',
        textAlign: 'center'
      },
      [theme.breakpoints.between(300, 380)]: {
        fontSize: '22px !important',
        textAlign: 'center'
      },
      [theme.breakpoints.between(380, 390)]: {
        fontSize: '24px !important',
        textAlign: 'center'
      },
    },
    gridButton: {
      display: 'flex',
      justifyContent: 'center',
      gap: 60,
      width: '100%',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        gap: 40,
      },
      [theme.breakpoints.down(485)]: {
        flexDirection: 'column',
        gap: 24
      },
    },
    // buttonStart: {
    //   maxWidth: '200px !important',
    //   [theme.breakpoints.down('xs')]: {
    //     maxWidth: '140px !important',
    //   },
    //   [theme.breakpoints.down(400)]: {
    //     maxWidth: '100px !important',
    //   },
    // },
    integrationText: {
      width: 'fit-content',
      border: '1px solid #ffffff',
      borderRadius: 24,
      paddingInline: 29,
      paddingBlock: 7,
      marginBottom: 16,
      background: 'transparent'
    },
    start: {
      display: 'flex',
      maxWidth: 440,
      textAlign: 'center',
      [theme.breakpoints.down(1280)]: {
        maxWidth: 300,
      },
    },
  }))

  const classes = useStyles()

  const getStarted = (url) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'GetStartedButtonClick',
      buttonName: 'Get Started',
      buttonLocation: 'Footer Banner',
      action: 'Click',
    })
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  return (
    <>
      {/* <Grid id='footer' container className={classes.root} justify="center">
        <Grid  container justify="center" className={classes.start}>
          <Text size='h2' className={classes.text} color={colors.white} weight="bold"> Start Your Free 14-Day Trial Today!</Text>
        </Grid>
        <Grid container justify="center" marginT={20}>
          <Grid className={classes.gridButton} xl={12} lg={12} md={12} sm={10} xs={10}>
            <Button className={classes.buttonStart} key="footer1" title="Get Started" buttonType="primaryMaybe" onClick={() => getStarted('https://dashboard.dataplayer.io/signup')} />
            <Link to="/demo" style={{ background: 'transparent !important', textDecoration: 'underline', color: colors.white }}>
              <Text size='footnote' color={colors.white}>Request a Demo</Text>
            </Link>     
          </Grid>
        </Grid>
      </Grid> */}
      <div id='footer' className={classes.root}>
        <div className={classes.start}>
          <Text size='h2' className={classes.text} color={colors.white} weight="bold"> Start Your Free 14-Day Trial Today!</Text>
        </div>
        <div className={classes.gridButton}>
          <Button className={classes.buttonStart} key="footer1" title="Get Started" buttonType="primaryMaybe" onClick={() => getStarted('https://dashboard.dataplayer.io/signup')} />
          <Link to="/demo" onClick={() => {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
              event: 'RequestDemoButtonClick',
              buttonName: 'Request Demo',
              buttonLocation: 'Footer Banner',
              action: 'Click',
            })
          }} style={{ background: 'transparent !important', textDecoration: 'underline', color: colors.white }}>
            <Text size='footnote' color={colors.white}>Request a Demo</Text>
          </Link>
        </div>
      </div>
    </>
  )
}

export default TryDP
