/*eslint-disable */
const COOKIES = [
  {
    title: '1. What Are Cookies?',
    desc: 'Cookies are small files placed on your device by websites you visit. They are widely used to improve user experience by remembering your preferences and activities. Cookies do not personally identify you but help identify your device.',
  },
  {
    title: '2. How We Use Cookies',
    desc: 'We use cookies for several purposes:' +
      '<ul>' +
      '<li><b>Account Management Cookies:</b> To manage your signup process and general administration. These cookies may persist after you log out to remember your site preferences.</li>' +
      '<li><b>Login Cookies:</b> To remember that you are logged in, so you don’t have to log in each time you visit a new page. These cookies are typically cleared upon logout.</li>' +
      '<li><b>Forms Cookies:</b> To remember your details for future correspondence when you submit data through forms on our Website.</li>' +
      '<li><b>Analytics Cookies:</b> We use Google Analytics to understand how you use our Website and to improve your experience. These cookies track metrics like time spent on pages and the pages visited. For more information on Google Analytics cookies, visit the <a href="https://policies.google.com/technologies/partner-sites">Google Analytics Privacy Policy.</a></li>' +
      '</ul>',
  },
  {
    title: '3. Disabling Cookies',
    desc: 'You can manage or disable cookies through your browser settings. Please note that disabling cookies may impact the functionality of our Website. Instructions for managing cookies can usually be found in your browser’s help section.',
  },
  {
    title: '4. Third-Party Cookies',
    desc: 'Our Website may contain links to third-party sites. We are not responsible for their cookie practices or privacy policies. We recommend reviewing the privacy policies of any third-party sites you visit.',
  },
  {
    title: '5. Security Information',
    desc: 'While we strive to protect your data, no internet transmission is completely secure. We take reasonable steps to safeguard any confidential information you provide to us.',
  }
]
const PRIVACYPOLICY = [
  {
    title: '1. Types of Data Collected',
    desc:  '<ul>' +
    '<li><b>Personal Data:</b> When you use our Service, we may request personal information that can be used to contact or identify you ("Personal Data"). This may include:' +
      '<ul>' +
      '<li>Email address</li>'+
      '<li>Full name</li>'+
      '<li>Clinic or business name</li>'+
      '<li>Time zone</li>'+
      '<li>Cookies and Usage Data</li>'+
      '</ul>' +
    '</li>' +
    '<li><b>Usage Data: </b> We collect information sent by your browser or device when you visit our Service. This may include:'+
      '<ul>' +
        '<li>IP address</li>'+
        '<li>Browser type and version</li>'+
        '<li>Pages visited</li>'+
        '<li>Time and date of visits</li>'+
        '<li>Time spent on pages</li>'+
        '<li>Device information (e.g., device ID, operating system)</li>'+
        '</ul>' +
    '</li>' +
    '<li><b>Forms Cookies:</b> To remember your details for future correspondence when you submit data through forms on our Website.</li>' +
    '<li><b>Analytics Cookies:</b> We use Google Analytics to understand how you use our Website and to improve your experience. These cookies track metrics like time spent on pages and the pages visited. For more information on Google Analytics cookies, visit the <a href="https://policies.google.com/technologies/partner-sites">Google Analytics Privacy Policy.</a></li>' +
    '</ul>',
  },
  {
    title: '2. How We Use Your Information',
    desc: 
      '<ul>' +
      '<li><b>To Provide and Improve Services:</b> We use your data to deliver, maintain, and enhance our services.' +
      '<li><b>To Communicate: </b> We may send newsletters, marketing, or promotional materials. You can opt out of these communications anytime.</li>' +
      '<li><b>To Ensure Security:</b> We use your data to monitor and ensure the security of our systems and services.</li>' +
      '</ul>',
  },
  {
    title: '3. Data Usage',
    desc: 'We may use your data for:' +
      '<ul>' +
      '<li>Providing customer support</li>' +
      '<li>Analysing and improving our Service</li>' +
      '<li>Detecting and addressing technical issues</li>' +
      '<li>Fulfilling contractual obligations, including billing and collections</li>' +
      '<li>Sending notices about your account or subscription</li>' +
      '</ul>',
  },
  {
    title: '4. Tracking Cookies Data',
    desc: 'We use cookies and similar tracking technologies to improve your experience on our website. For more information, please review our <a href="/cookies">Cookie Policy</a>.',
  },
  {
    title: '5. Retention of Data',
    desc: 'We retain your Personal Data only as long as necessary for the purposes outlined in this Privacy Policy. If you cancel or terminate your account, all data will be deleted within 3 days. We recommend exporting any necessary information before cancellation.',
  },
  {
    title: '6. Transfer of Data',
    desc: 'Your data may be transferred to and maintained on servers located outside your jurisdiction. By providing us with information, you consent to this transfer and processing.',
  },
  {
    title: '7. Security of Data',
    desc: 'We use third-party vendors and hosting partners for the necessary technology to run Dataplayer. While we own the code and databases, you retain rights to your data. Contact us if you wish to have your data completely deleted from our servers.'+
    '<br/>'+
    'We implement administrative, technical, and physical measures to protect your data. However, no system is infallible, and we cannot guarantee absolute security. We will notify you of any data breaches as required by law.'
  },
  {
    title: '8. Payments',
    desc: 'For paid services, we use <a href="https://www.paddle.com/">Paddle</a> for payment processing. We do not store your payment card details; these are handled directly by Paddle, whose privacy practices are described in their <a href="https://www.paddle.com/legal/privacy">Privacy Policy.</a>. ',
  },
  {
    title: '9. Links to Other Sites',
    desc: 'Our Service may contain links to third-party websites. We do not control and are not responsible for the content or privacy practices of these sites. We encourage you to review their privacy policies.',
  },
  {
    title: '10. Children’s Privacy',
    desc: 'Our Service is not intended for children under 18. We do not knowingly collect information from children under 18. If we become aware of such data, we will take steps to remove it from our servers.',
  },
  {
    title: '11. Changes to This Privacy Policy',
    desc: 'We may update our Privacy Policy periodically. Changes will be posted on this page with an updated date. We will notify you of significant changes via email or through a prominent notice on our Service.',
  }
]

export { COOKIES, PRIVACYPOLICY }

