import React from 'react'
import {  ButtonBase } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { colors } from '../../Themes'

const Pressable = withStyles({
  root: {
    textTransform: 'none',
    color: colors.white,
    backgroundColor: colors.primary,
    borderRadius: 10,
    minHeight: 50,
    '&:hover': {
      backgroundColor: colors.primaryHover,
    },
  },
})(props => <ButtonBase {...props} />)
  
export {
  Pressable
}