import React from 'react'
import parse from 'html-react-parser'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import {
  Collapse,
  CardContent,
} from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import ArrowDropDownSharpIcon from '@material-ui/icons/ArrowDropDownSharp'
import { Pressable } from '../Button/Pressable'
import { colors } from '../../Themes'
import { MinusIcon, PlusIcon } from '../../Themes/Images'
import Text from '../Text'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    borderRadius: 0
  },
  rootCard: {
    padding: 0
  },
  media: {
    height: 0,
    paddingTop: '56.25%',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {},
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    color: colors.secondary,
  },
  avatar: {
    backgroundColor: red[500],
  },
  collapseContent: {
    boxSizing: 'border-box',
    width: '100%',
    padding: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: colors.white,
    borderBottom: '1px solid  #C9C9C9',
    borderRadius: 0,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 15,
      textAlign: 'left'
    },
  },
  collapseContentOPen: {
    boxSizing: 'border-box',
    width: '100%',
    padding: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: colors.white,
    borderRadius: 0,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 15,
      textAlign: 'left'
    },
  },
  collapseContentTerms: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '15px 15px 15px 0',
    justifyContent: 'space-between',
    backgroundColor: colors.white,
    borderBottom: '1px solid  #C9C9C9',
    borderRadius: 0,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left'
    },
  },
  collapseContentOPenTerms: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '15px 15px 15px 0',
    justifyContent: 'space-between',
    backgroundColor: colors.white,
    borderRadius: 0,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left'
    },
  },
  collapseIcon: {
    height: 24,
    width: 24,
    objectFit: 'contain'
  },
  collapseItem: {
    display: 'flex',
    backgroundColor: colors.drawer,
    alignItems: 'center',
  },
  pressable: {
    width: '100%',
    color: colors.primary,

    transitionDelay: '0.38s',
    overflow: 'hidden',
    borderRadius: 0
  },
  pressableOpen: {
    width: '100%',
    color: colors.primary,
    overflow: 'hidden',
    borderRadius: 0
  },
  title: {
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    margin: 0,
    fontSize: 24,
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  expandOpenTittle: {
    color: colors.secondary,
    fontSize: 24,
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  collapse: {
    backgroundColor: colors.white,
    width: '100%',
    paddingLeft: 15,
  },
  collapseOpen: {
    backgroundColor: colors.white,
    width: '100%',
    paddingLeft: 15,
    borderBottom: '1px solid #c9c9c9'
  },
  collapseTerms: {
    backgroundColor: colors.white,
    width: '100%',
    paddingLeft: 0,
  },
  collapseOpenTerms: {
    backgroundColor: colors.white,
    width: '100%',
    paddingLeft: 0,
    borderBottom: '1px solid #c9c9c9'
  },
  // why styles
  whytitle: {
    margin: 0,
  },
  whyexpandOpenTittle: {
    margin: 0,
  },
}))

function TermsCollapse({
  title,
  description,
  isExpanded,
  expand,
}) {
  const classes = useStyles()

  return (
    <div style={{ padding: 0, paddingBlock: 16, display: 'flex', flexDirection: 'column', borderBlock: '1px solid #ccc' }} className={{ root: classes.rootCard }}>
      <Text size='h5' weight='medium'>{title}</Text>
      <Text size='body' color={colors.darkGrey} weight='regular' style={{ marginTop: 8 }}>{parse(description)}</Text>
    </div>
  )
}

function WhyCollapse({
  title,
  isExpanded,
  expand,
  children
}) {
  const classes = useStyles()

  return (
    <>
      <Pressable
        onClick={expand}
        classes={
          isExpanded
            ? { root: classes.pressableOpen }
            : { root: classes.pressable }
        }
      >
        <div className={isExpanded ? classes.collapseContentOPen : classes.collapseContent}>
          <Text size={isExpanded ? 'h5' : 'footnote'} weight={isExpanded ? 'medium' : 'normal'} style={{ textAlign: 'left' }}
            className={clsx(classes.whytitle, {
              [classes.whyexpandOpenTittle]: isExpanded,
            })}
          >
            {title}
          </Text>
          {isExpanded ? <img src='https://dyl2njn37ytxk.cloudfront.net/websiteSEO/svg/arrow.svg' alt='arrowUp' style={{ transform: 'rotateX(0.5turn)', fill: colors.primary, width: 14 }} />
            :
            <img src='https://dyl2njn37ytxk.cloudfront.net/websiteSEO/svg/arrow.svg' alt='arrowDown' style={{ fill: colors.primary, width: 14 }} />}
          {/* <ArrowDropDownSharpIcon
            className={clsx(classes.expand, {
              [classes.expandOpen]: isExpanded,
            })}
          /> */}
        </div>
      </Pressable>
      <Collapse
        className={isExpanded ? classes.collapseOpen : classes.collapse}
        in={isExpanded}
        timeout="auto"
        unmountOnExit
      >
        <CardContent style={{ padding: 0, paddingBottom: 16 }} >
          {children}
        </CardContent>
      </Collapse>
    </>
  )
}

export { TermsCollapse, WhyCollapse }
