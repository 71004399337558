import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import AppNew from './AppNew'
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter as Router } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#98235C',
    },
    secondary: {
      main: '#EC4252',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 760,
      md: 960,
      lg: 1280,
      xl: 1920,
      // xlsmaller: 1919
      xxl: 2000
    },
  },
})

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Router>
      <AppNew />
    </Router>
  </ThemeProvider>
  ,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
