import React, { useEffect } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useHistory } from 'react-router'
import Text from './../Text'
import { colors } from '../../Themes'
import { useMediaQuery } from '@material-ui/core'
import { TegeriaCredit } from '../../Themes/Images'

const THE_PRODUCT=[
  {
    id: '1',
    tittle: 'What We Do',
    link: '/about'
  },
  {
    id: '2',
    tittle: 'Plans & Pricing',
    link: '/pricing',
    scrollTo: 'PLANS'
  },
  {
    id: '3',
    tittle: 'Data Security',
    link: '/security'
  },
  {
    id: '4',
    tittle: 'Terms of Service',
    link: '/terms'
  },
  {
    id: '5',
    tittle: 'Cookie Policy',
    link: '/cookies'
  }
]

const THE_PRODUCT_MOBILE=[
  {
    id: '3',
    tittle: 'Privacy & Security',
    link: '/security'
  },
  {
    id: '4',
    tittle: 'Terms & Conditions',
    link: '/terms'
  },
  {
    id: '5',
    tittle: 'Cookies',
    link: '/cookies'
  }
]


const COMMUNITY=[
  {
    id: '5',
    tittle: 'Case Studies',
    link: '/testimonials',
  },
  {
    id: '1',
    tittle: 'Common Questions',
    link: '/faq',
  },
  {
    id: '2',
    tittle: 'Privacy Policy',
    href: '/privacyPolicy'
  },
  {
    id: '3',
    tittle: 'Our Partners',
    link: '/partners',
  },
  {
    id: '4',
    tittle: 'Get in Touch',
    link: '/contactus'
  },
  
]

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    display: 'flex',
    flexFlow: 'column ',
    paddingBottom: 40,
    marginInline: 'auto',
    maxWidth: 1100,
    [theme.breakpoints.up(1921)]: {
      maxWidth: 1300,
    },
    [theme.breakpoints.down(1440)]: {
      maxWidth: 1000,
    },
    [theme.breakpoints.down(1100)]: {
      maxWidth: 900,
    },
    [theme.breakpoints.down(960)]: {
      maxWidth: 700,
    },
    [theme.breakpoints.down(760)]: {
      maxWidth: '100%',
      paddingInline: 16,
      paddingBlock: 30
    },
  },
  footerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
  },
  footerItem: {
    display: 'flex',
    flexFlow: 'column ',
    flex: 1,
    marginBottom: 30,
    flexBasis: '40%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginBottom: 20,
      marginTop: 5
    },
  },
  footerItem2: {
    display: 'flex',
    flexFlow: 'column ',
    flex: 1,
    marginLeft: 50,
    marginBottom: 30,
    flexBasis: '30%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginBottom: 0,
      marginTop: 5
    },
  },
  footerItem4: {
    display: 'flex',
    flexFlow: 'column ',
    flex: 1,
    marginBottom: 30,
    flexBasis: '30%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginBottom: 20,
      marginTop: 5
    },
  },
  logo: {
    height: 70,
    width: 200,
    imageRendering: '-webkit-optimize-contrast',
  },
  footerDesc: {
    color: '#555555',
    [theme.breakpoints.only('sm')]: {
      width: '45%',
    },
  },
  footerTitle: {
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px !important'
    },
  },
  footerLink: {
    marginBottom: 13,
    cursor: 'pointer',
    fontSize: '16px',
    color: '#555',
    '&:hover': {
      color: colors.accentHover
    },
  },
  community: {
    marginBottom: 13,
    cursor: 'pointer',
    '&:hover': {
      color: colors.accentHover
    }
  },
  socialMedia: {
    display: 'flex',
    flexFlow: 'column ',
    marginLeft: 50,
    marginBottom: 120,
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      marginBottom: 50,
    },
  },
  poweredBy: {
    display: 'flex',
    // marginTop: 20,
    gap: 20,
    [theme.breakpoints.down('sm')]: {
      marginTop: '20px'
    },
  },
  link: {
    height: 40,
    width: 40
  },
  linkedinLogo: {
    height: 40,
    width: 40,
    marginLeft: 10
  },
}))

const footerMobileStyles = makeStyles((theme) => ({
  footerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  footerItem: {
    display: 'flex',
    flexFlow: 'column ',
    gap: 8
  },
  footerItem3: {
    display: 'flex',
    flexFlow: 'column ',
    flex: 1,
    marginBottom: 30,

  },
  footerItem2: {
    display: 'flex',
    flexFlow: 'column ',
    gap: 8
  },
  logo: {
    height: 70,
    width: 200,
    imageRendering: '-webkit-optimize-contrast',
  },
  itemsHolder: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginBottom: 30,
    [theme.breakpoints.down(960)]: {
      justifyContent: 'flex-start',
      gap: 80
    },
    [theme.breakpoints.down(460)]: {
      justifyContent: 'flex-start',
      gap: 50
    },
  },
  socialMedia: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginBottom: 30
  },
  poweredBy: {
    display: 'flex',
    flexFlow: 'column ',
    gap: 12,
    marginTop: 16,
    minHeight: 110
  },
  footerLink: {
    cursor: 'pointer',
    fontSize: '16px',
    color: '#555',
  },
}))

const FooterMobile = () => {
  const history = useHistory()

  const linkTo =(url, where)=>{
    history.push({
      pathname: url,
      search: where,
    })
  }

  const classes = footerMobileStyles()
  return (
    <div className={classes.footerWrapper}  >
      <div className={classes.footerItem3}>
        <div className={classes.logo}>
          <img src='https://dyl2njn37ytxk.cloudfront.net/websiteSEO/svg/dataplayer%20logo.svg' alt='dataplayerLogo' loading='lazy' />
        </div>
        <Text style={{ fontSize: '16px', color: '#555555', }} weight="regular" color='#555555' className={classes.footerDesc} >
        Your go-to platform for data insights and reporting, tailored for healthcare practices.
        </Text>
      </div>
      <div className={classes.itemsHolder}>
        <div className={classes.footerItem2}>
          {THE_PRODUCT.map((item)=>{
            return (  
              <div key={item.id} className={classes.footerLink}  onClick={item.scrollTo ? ()=> history.push({
                pathname: item.link,
                scrollTo: item.scrollTo
              }) : ()=> history.push(item.link)} >
                <Text style={{ fontSize: '16px', color: '#555555', }} className={classes.footerLink}  weight="regular" color='#555'>
                  {item?.tittle}
                </Text>
              </div>)
          })
          }
          {/* <div className={classes.poweredBy}>
            <Text size='body' weight='regular' color='#555'>Partner of</Text>
            <img src='https://dyl2njn37ytxk.cloudfront.net/websiteSEO/svg/aws.svg' alt='aws' loading='lazy' onClick={() => 
              window.open('https://partners.amazonaws.com/partners/0010h00001hfu2BAAQ/Tegeria', '_blank')
            } style={{ cursor: 'pointer', width: 46 }} />
          </div> */}
        </div>
        <div className={classes.footerItem}>
          { 
            COMMUNITY.map((item)=>{
              return (  
                <>
                  {item.href ?
                    <a key={item.id} href={item?.href} className={classes.community} style={{ textDecoration: 'none' }}>
                      <Text style={{ fontSize: '16px', color: '#555555', }} className={classes.footerLink} weight="regular" color='#555'>
                        {item?.tittle}
                      </Text>
                    </a>
                    : 
                    <div key={item.id} className={classes.footerLink}  onClick={()=>linkTo(item.link, item?.query)} >
                      <Text style={{ fontSize: '16px', color: '#555555', }} className={classes.footerLink} weight="regular" color='#555'>
                        {item?.tittle}
                      </Text>
                    </div>
                  }
                </>
              )
            })
          }
          {/* <div className={classes.poweredBy}>
            <Text size='body' weight='regular' color='#555'>Powered by</Text>
            <TegeriaCredit />
          </div> */}
        </div>
      </div>
      {/* <div className={classes.socialMedia}>
        <div className={classes.poweredBy}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Text size='body' weight='regular' color='#555'>Partner of</Text>
            <img src='https://dyl2njn37ytxk.cloudfront.net/websiteSEO/svg/aws.svg' alt='aws' loading='lazy' onClick={() => 
              window.open('https://partners.amazonaws.com/partners/0010h00001hfu2BAAQ/Tegeria', '_blank')
            } style={{ marginTop: 10, cursor: 'pointer', width: 46 }} />
          </div>
        </div>
        <div className={classes.poweredBy}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Text size='body' weight='regular' color='#555'>Partner of</Text>
            <img src='https://dyl2njn37ytxk.cloudfront.net/websiteSEO/svg/aws.svg' alt='aws' loading='lazy' onClick={() => 
              window.open('https://partners.amazonaws.com/partners/0010h00001hfu2BAAQ/Tegeria', '_blank')
            } style={{ marginTop: 10, cursor: 'pointer', width: 46 }} />
          </div>
        </div>
      </div> */}
    </div>
  )
}

function NewFooter() {
  const classes = useStyles()
  const history = useHistory()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const linkTo =(url, where)=>{
    history.push({
      pathname: url,
      search: where,
    })
  }

  useEffect(() => {
    if (location?.scrollTo) {
      document.getElementById(location?.scrollTo).scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [])

  return (
    <div className={classes.footerContainer}>
      <div style={{ width: '100%', height: 1, borderBottom: '1px solid #ccc', marginBottom: 24 }} />
      {isMobile ? <FooterMobile /> : 
        <div className={classes.footerWrapper}  >
          <div className={classes.footerItem}>
            <div className={classes.logo}>
              <img src='https://dyl2njn37ytxk.cloudfront.net/websiteSEO/svg/dataplayer%20logo.svg' alt='dataplayerLogo' loading='lazy' />
            </div>
            <Text style={{ fontSize: '16px', color: '#555555', maxWidth: '80%' }} weight="regular" color='#555555' className={classes.footerDesc} >
            Your go-to platform for data insights and reporting, tailored for healthcare practices.
            </Text>
          </div>
          <div className={classes.footerItem2}>
            {
              THE_PRODUCT.map((item)=>{
                return (  
                  <div key={item.id} className={classes.footerLink}  onClick={item.scrollTo ? ()=> history.push({
                    pathname: item.link,
                    scrollTo: item.scrollTo
                  }) : ()=> history.push(item.link)} >
                    <Text style={{ fontSize: '16px', color: '#555555', }} className={classes.footerLink}  weight="regular" color='#555'>
                      {item?.tittle}
                    </Text>
                  </div>)
              })}
          </div>
          <div className={classes.footerItem4}>
            { 
              COMMUNITY.map((item)=>{
                return (  
                  <>
                    {item.href ?
                      <a key={item.id} href={item?.href}  className={classes.community} style={{ textDecoration: 'none' }}>
                        <Text style={{ fontSize: '16px', color: '#555555', }} className={classes.footerLink} weight="regular" color='#555'>
                          {item?.tittle}
                        </Text>
                      </a>
                      : 
                      <div key={item.id} className={classes.footerLink}  onClick={()=>linkTo(item.link, item?.query)} >
                        <Text style={{ fontSize: '16px', color: '#555555', }} className={classes.footerLink} weight="regular" color='#555'>
                          {item?.tittle}
                        </Text>
                      </div>
                    }
                  </>
                )
              })
            }
          </div>
          {/* <div className={classes.socialMedia}>
            <div className={classes.poweredBy}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Text size='body' weight='regular' color='#555'>Partner of</Text>
                <img src='https://dyl2njn37ytxk.cloudfront.net/websiteSEO/svg/aws.svg' alt='aws' loading='lazy' onClick={() => 
                  window.open('https://partners.amazonaws.com/partners/0010h00001hfu2BAAQ/Tegeria', '_blank')
                } style={{ marginTop: 10, cursor: 'pointer', width: isMobile ? 46 : 54 }} />
              </div>
            </div>
          </div> */}
        </div>
      }
      <div style={{ width: '100%', height: 1, borderBottom: '1px solid #ccc', marginBottom: 17 }} />
      <div style={{ display: 'flex', flexDirection: isMobile ?'column' : 'row', alignItems: 'center', gap: isMobile ? 16 : 0, justifyContent: isMobile ? 'center' : 'space-between' }}>
        {isMobile &&<div className={classes.poweredBy}>
          <Text size='body' weight='regular' color='#555'>Powered by</Text>
          <TegeriaCredit />
        </div>
        }
        <Text size="footnote" weight="regular" color={colors.primary} style={{ alignSelf: 'center' }}>
      © Copyright {new Date().getFullYear()}, Dataplayer. All rights are reserved
        </Text>
        {!isMobile && 
        <Text size="footnote" weight="regular" color={colors.primary} style={{ alignSelf: 'center' }}>
    Powered by <TegeriaCredit style={{ marginLeft: 15 }} />
        </Text>}
      </div>
    </div>
  )
}

export default NewFooter
