import React from 'react'
import {
  fade,
  makeStyles,
  withStyles
} from '@material-ui/core/styles'
import clsx from 'clsx'
import FilledInput from '@material-ui/core/FilledInput'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import { colors } from '../../Themes'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

// Input product format

const useStylesForInput = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    alignSelf: 'stretch',
  },
  input: {
    flexGrow: 1,
    borderWidth: 5,
    borderColor: colors.accent,
    minWidth: '100%',
    marginTop: 8,
    marginBottom: 8,
    [theme.breakpoints.down('sm')]: {
      marginTop: 8,
      marginBottom: 8,
    },
  },
}))

const Input = withStyles({
  root: {
    borderRadius: 5,
    color: colors.primary,
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: 18,
    backgroundColor: '#f5f5f5',
    border: '1px solid #f5f5f5',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
    '&$focused': {
      backgroundColor: '#f5f5f5',
    },
    '&$disabled': {
      color: colors.primaryRGBA(0.5),
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontSize: 18,
      backgroundColor: colors.background,
    },
  },
  underline: {
    border: '1px solid #f5f5f5',
    '&:after': {
      border: '1px solid ' + colors.primary,
      top: 0,
      borderRadius: 5,
      transform: 'scaleX(1)',
      opacity: 0,
    },
    '&$focused:after': {
      transform: 'scaleX(1)',
      opacity: 1,
    },
    '&:before': {
      borderWidth: 0,
    },
    '&:hover:before': {
      borderWidth: 0,
      pointerEvents: 'none',
    },
    '&$disabled:before': {
      borderWidth: 0,
      pointerEvents: 'none',
    },
  },
  focused: {},
  disabled: {},
})((props) => <FilledInput {...props} />)

const InputExitIntent = withStyles({
  root: {
    borderRadius: 10,
    color: colors.primary,
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: 18,
    maxHeight: 50,
    backgroundColor: colors.white,
    '&:hover': {
      backgroundColor: colors.white,
    },
    '&$focused': {
      backgroundColor: colors.white,
    },
    '&$disabled': {
      color: colors.primaryRGBA(0.5),
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontSize: 18,
      backgroundColor: colors.background,
    },
  },
  underline: {
    border: '1px solid #c9c9c9',
    '&:after': {
      border: '1px solid ' + colors.accent,
      top: 0,
      borderRadius: 10,
      transform: 'scaleX(1)',
      opacity: 0,
    },
    '&$focused:after': {
      transform: 'scaleX(1)',
      opacity: 1,
    },
    '&:before': {
      borderWidth: 0,
    },
    '&:hover:before': {
      borderWidth: 0,
      pointerEvents: 'none',
    },
    '&$disabled:before': {
      borderWidth: 0,
      pointerEvents: 'none',
    },
  },
  focused: {},
  disabled: {},
})((props) => <FilledInput {...props} />)

function TextInput({
  label,
  value,
  disabled,
  setValue,
  costumizedInputStyles,
  error,
  type,
  onChange,
  isExit,
  ...rest
}) {
  const classes = useStylesForInput()

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  return (
    <div className={classes.root}>
      <FormControl
        className={clsx(classes.input, costumizedInputStyles)}
        variant="filled"
      >
        <InputLabel
          htmlFor="input"
          className={disabled ? 'passwordLabelDisabled' : 'passwordLabel'}
        >
          {label}
        </InputLabel>
        {isExit ? 
          <InputExitIntent
            id="textInput"
            type={type}
            value={value || ''}
            autoComplete="off"
            disableUnderline={error}
            onChange={onChange ? onChange : handleChange}
            disabled={disabled}
            {...rest}
          />
          :
          <Input
            id="textInput"
            type={type}
            value={value || ''}
            autoComplete="off"
            disableUnderline={error}
            onChange={onChange ? onChange : handleChange}
            disabled={disabled}
            {...rest}
          />}
      </FormControl>
    </div>
  )
}

TextInput.defaultProps = {
  disabled: false,
  error: false,
  type: 'text'
}

export { TextInput }

