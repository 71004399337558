import { useCallback, useState } from 'react'

function validateSignup(input) {
  let errors = {}

  switch (input.key) {
  case 'fullName': {
    if (!input.value) {
      errors.fullName = 'Please fill out the required information.'
    }
    return errors
  }
  case 'clinicName': {
    if (!input.value) {
      errors.clinicName = 'Please fill out the required information.'
    }
    return errors
  }
  case 'email': {
    if (!input.value) {
      errors.email = 'Please fill out the required information.'
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(input.value)
    ) {
      errors.email = 'Please enter a valid email address.'
    }
    return errors
  }

  case 'message': {
    if (!input.value) {
      errors.message = 'Please fill out the required information.'
    }
    return errors
  }

  default:
    return errors
  }
}

const useFormContact = (callback, setValues) => {
  const [errors, setErrors] = useState({})

  const handleSubmit = (event) => {
    if (event) event.preventDefault()
    if (Object.keys(errors).length === 0) {
      callback()
    }
  }

  const handleChange = useCallback((value, name) => {
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }))
    delete errors[name]
  }, [errors, setValues])

  const handleOnBlur = (e) => {
    e.preventDefault()
    const { name, value } = e.target
    const input = {
      key: name,
      value,
    }
    const validateError = validateSignup(input)
    setErrors({
      ...errors,
      ...validateError,
    })
  }

  return {
    handleChange,
    handleSubmit,
    handleOnBlur,
    errors,
    setErrors,
  }
}

export { useFormContact }
