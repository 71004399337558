import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useCStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 60,
    height: '100vh',
    alignItems: 'center',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  bottom: {
    color: 'linear-gradient(to right,#90205D, #EE4352)'
  }
}))

function CircularIndeterminate() {
  const classes = useCStyles()

  return (
    <div className={classes.root}>
      <CircularProgress className={classes.bottom} style={{ width: 80, height: 80 }} />
    </div>
  )
}

export default CircularIndeterminate