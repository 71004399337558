import React, { useLayoutEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import NewFooter from '../Components/Footer/NewFooter'
import NewHeader from './NavBar'
import TryDP from '../Components/TryDP'
import Text from '../Components/Text'
import { colors } from '../Themes'

const HEADERS = [
  {
    path: '/security',
    title: 'Privacy Policy',
  },
  {
    path: '/pricing',
    title: 'Pricing Plans',
  },
  {
    path: '/terms',
    title: 'Terms & conditions',
  },
  {
    path: '/about',
    title: 'Data analytics and visualisations platform',
  },
  {
    path: '/cookies',
    title: 'Website cookies and security information.',
  },
  {
    path: '/faq',
    title: ' Frequently Asked Questions',
  },
  {
    path: '/podiatry2023',
    title: '30% lifetime discount on annual plans for event attendees',
    subtitle: 'Sign up to our mailing list to get access to the benefits and support to set up your account.'
  },
  {
    path: '/therapyexpo',
    title: '30% lifetime discount on annual plans for event attendees',
    subtitle: 'Sign up to our mailing list to get access to the benefits and support to set up your account.'
  },
  {
    path: '/help-centre',
    title: 'Help Centre',
  }
]

const useStyles = makeStyles((theme) => ({
  mainSection: {
    display: 'flex',
    paddingLeft: '10%',
    paddingRight: '10%',
    transition: '0.5s',
    height: '13rem',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.between(1280, 1366)]: {
      paddingLeft: '6%',
      paddingRight: '6%',
    },
    [theme.breakpoints.down('sm')]: {
      height: '9rem',
    },
    [theme.breakpoints.between(639, 781)]: {
      paddingLeft: '2%',
      paddingRight: '2%',
    },
  },
  spacer: {
    width: '100%',
    height: '80px'
  },
  subTitle: {
    maxWidth: 600,
    textAlign: 'center'
  }
}))

const Header = () => {
  const classes = useStyles()
  const location = useLocation()

  const found = HEADERS.findIndex(
    (item) => item.path === location?.pathname
  )

  const title = HEADERS?.[found]?.title || ''
  const subTitle = HEADERS?.[found]?.subtitle || ''

  return (
    <div id='layout' style={{ height: subTitle && '13rem' }} className={classes.mainSection}>
      <h1 style={{ textAlign: 'center', color: colors.white }} className='headerH1'>{title}</h1>
      <div className={classes.subTitle}>
        <Text color={colors.white} size='h4' style={{ textAlign: 'center' }} className='headerH1'>{subTitle}</Text>
      </div>
    </div>
  )
}

const Layout = ({ children, isArticle }) => {
  const location = useLocation()
  const classes = useStyles()

  const show = HEADERS.findIndex(
    (item) => item.path === location?.pathname
  )

  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
      // behavior: 'smooth',
    })
  }, [location?.pathname])

  return (
    <>
      <NewHeader />
      <main className="root">
        <div className={classes.spacer} />
        {/* {show !== -1 && <Header />} */}
        {children}
      </main>
      {location?.pathname === '/demo' || location?.pathname === '/contactus' || location?.pathname === '/faq' || 
       location?.pathname === '/partners' || (location?.pathname === '/partners' && isArticle) ? null :  <TryDP />}
      <div style={{ height: 13, width: '100%', background: '#F4F4F4', marginTop: 85, marginBottom: 32 }} />
      <NewFooter />
    </>
  )
}

export default Layout