import { useEffect } from 'react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

function useScrollDirection(scrollDirection, setScrollDirection) {
  
  useEffect(() => {
    let lastScrollY = window.scrollY
  
    const updateScrollDirection = () => {
      const scrollY = window.scrollY
      const direction = scrollY > lastScrollY ? 'down' : 'up'
      if (direction && scrollY && lastScrollY && direction !== scrollDirection && (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)) {
        setScrollDirection(direction)
      }
      lastScrollY = scrollY > 0 ? scrollY : 0
    }
    window.addEventListener('scroll', updateScrollDirection) // add event listener
    return () => {
      window.removeEventListener('scroll', updateScrollDirection) // clean up
    }
  }, [scrollDirection, setScrollDirection])
  
  return scrollDirection
}

export default useScrollDirection