/* eslint-disable no-undef */
import ReactPixel from 'react-facebook-pixel'
import ReactGA from 'react-ga4'

const ZohoChatScipt=()=>{
  let s = document.createElement('script')
  let d=document
  let t=d.getElementsByTagName('script')[0]
  s=d.createElement('script')
  s.type='text/javascript'
  s.id='zsiqscript';s.defer=true
  s.src='https://salesiq.zoho.eu/widget'
  t=d.getElementsByTagName('script')[0]
  t.parentNode.insertBefore(s, t)
  // d.write('<div id=\'zsiqwidget\'></div>')
  return document.body.appendChild(s)
}

const FacebookPixelCode=()=>{
  const options = {
    autoConfig: true, 
    debug: false, 
  }
  ReactPixel.init('326923166225454', options)
  ReactPixel.pageView() 
  return ReactPixel
}
// const GoogleAnl=()=>{
//   ReactGA.initialize('UA-121642212-1')
//   ReactGA.pageview(window.location.pathname)
//   return ReactGA
// }

// const GoogleAnlV2=()=>{
//   ReactGA.initialize('AW-10892397934')
//   ReactGA.pageview(window.location.pathname)
//   return ReactGA
// }

const GoogleAnlV3=()=>{
  ReactGA.initialize('G-5GHS89EJ9S')
  // ReactGA.pageview(window.location.pathname + window.location.search)
  return ReactGA
  
}


export { ZohoChatScipt, FacebookPixelCode, GoogleAnlV3 }